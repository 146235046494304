import http from '@/http'
const URL = '/admin/settings'

// export const setting = () => http.get(URL)
export const status = () => http.get(URL + '/status')
// export const saveSetting = (payload) => http.post(URL + `/${payload.get('id')}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })

export default {
  status
}
