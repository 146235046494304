import { fetchDetails } from '@/modules/check-in/service/fetchDetails'
import { fetchTags } from '@/modules/check-in/service/fetchTags'

export const CHECK_IN_MODULE = 'CHECK_IN'

export default {
  state: {
    list: {},
    tags: {
      list: [],
      alreadyLoaded: false
    },
    showDetailId: null
  },
  actions: {
    async fetchDetail ({ state, commit, dispatch }, { id }) {
      await dispatch('loadTagsList')
      const response = await fetchDetails(id)
      commit('setItem', {
        id: response.id,
        isStatusCheckIn: response.isStatusCheckIn,
        isStatusCheckOut: response.isStatusCheckOut,
        isStatusUnseen: !response.isStatusCheckIn && !response.isStatusCheckOut,
        name: response.name,
        email: response.email,
        codId: response.codId,
        grupo: response.grupo,
        tags: state.tags.list.map(tag => ({ ...tag, isChecked: response.tagsId.includes(tag.id) })),
        historico: response.historico,
        activitiesList: response.activitiesList,
        commentsList: response.commentsList
      })
    },
    async loadTagsList ({ state, commit }) {
      if (state.tags.alreadyLoaded) {
        return
      }
      const tags = await fetchTags()
      commit('updateTags', { tags })
    }
  },
  getters: {
    detailById: state => id => {
      return state.list[id] ?? undefined
    }
  },
  mutations: {
    setItem (state, {
      id,
      isStatusCheckIn,
      isStatusCheckOut,
      isStatusUnseen,
      name,
      email,
      codId,
      grupo,
      tags,
      historico,
      activitiesList,
      commentsList
    }) {
      const item = {
        id,
        isStatusCheckIn,
        isStatusCheckOut,
        isStatusUnseen,
        name,
        email,
        codId,
        grupo,
        tags,
        historico,
        activitiesList,
        commentsList
      }
      state.list = { ...state.list, [id]: item }
    },
    updateTags (state, { tags }) {
      state.tags.alreadyLoaded = true
      state.tags.list.push(...tags)
    },
    updateItemStatus (state, { id, isStatusCheckIn, isStatusCheckOut, isStatusUnseen, userLoggedInName = '' }) {
      const item = state.list[id]
      if (!item) {
        return
      }
      item.isStatusCheckIn = isStatusCheckIn
      item.isStatusCheckOut = isStatusCheckOut
      item.isStatusUnseen = isStatusUnseen
      if (!item.isStatusUnseen) {
        item.activitiesList.unshift({
          at: new Date(),
          by: userLoggedInName,
          isManual: false,
          isStatusCheckIn: isStatusCheckIn
        })
      }
    },
    setShowDetailId (state, id) {
      state.showDetailId = id
    }
  },
  namespaced: true
}
