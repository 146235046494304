export default [
  {
    name: 'settings-emails',
    path: '/settings/emails',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'E-mail',
      icon: 'dvr',
      label: 'E-mail',
      roles: []
    }
  },
  {
    name: 'settings-email-edit',
    path: '/settings/emails/:id/show',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'E-mail - Edição',
      icon: 'dvr',
      label: 'E-mail - Edição',
      roles: []
    }
  }
]
