import http from '@/http'
const URL = '/admin/settings-checkin'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}
export const listCheckin = async (params) => {
  const list = (await http.get(URL + `/list?include=participant,tag,operator`, { params: params }))
  return list
}
export const exportChecklist = async (params) => {
  const list = (await http.get(URL + `/export`, { params: params, responseType: 'blob' })).data
  return list
}
export const resetCheckin = (payload) => http.put(URL + `/reset`, payload)
export const toogleSetting = (payload) => http.put(URL + `/checkout`, payload)

export default {
  list,
  listCheckin,
  exportChecklist,
  resetCheckin,
  toogleSetting
}
